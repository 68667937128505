// 点后推搜索词

import { defineComponent } from 'vue'
import {
  clickPushBackEventType,
  onTouchCPBSRequestInInformationFlow,
  onTouchCPBSShowInInformationFlow,
} from '../../../mounting/eventBus/eventLib/compose/clickPushBackSearch'
import {
  createCPBSPopup,
  requestRecommendSearchWords,
} from '../../../product_list_v2/js/clickPushBackSearchUtils'
import { getFeedbackRecInSession } from 'public/src/pages/product_list_v2/js/utils'
import { abtservice } from 'public/src/services/abt'
// import { emitShowProductCardPopup } from '../../mounting/eventBus/eventLib/eventEmit'

// 注销事件订阅
let offTouchCPBSRequestInInformationFlow
let offTouchCPBSShowInInformationFlow

let requestCPBSPromise

export default defineComponent({
  data() {
    return {
      cpbsTimingAbt: '',
      cpbsStyleAbt: '',
      targetProductCard: null,
      cpbsGoodIdList: [],
      cpbsAbtTest: '',
      infoFlowShowVideo: '',
    }
  },
  unmounted() {
    this.offCPBSWatcher()
  },
  // 放到了initData方法中 abt加载完再触发
  // mounted() {
  //   this.handleInitCPBSWatcher()
  // },
  methods: {
    getProductListItemInstance(index) {
      const productListItemRef =
        this.$refs?.informationWaterFlow?.$refs?.[
          `productListItem_${index}`
        ]
      return productListItemRef?.[0] || productListItemRef
    },
    handleRequestCPBS({ type, params }) {
      requestCPBSPromise = null
      let requestCPBSParams = params

      const isAddBagScreen = ['addbag', 'noaddbag'].includes(this.cpbsTimingAbt)
      // 加车场景需在点击信息流商卡时记录商卡信息
      // 在信息流落地页加车成功/失败或从商详返回时再发起请求
      if (type === clickPushBackEventType.clickProductCard && isAddBagScreen && !this.isJumpDetail) {
        this.targetProductCard = params
        return
      }
      // 加车场景需商详返回时再发起请求
      if (type === clickPushBackEventType.reEnterPick) {
        if (!params?.isBackFromDetail || !isAddBagScreen) {
          return
        }
        requestCPBSParams = this.targetProductCard
      }
      // 在信息流落地页加车成功/失败时再发起请求
      if (type === clickPushBackEventType.openQuickCartResult) {
        const quickCartSuccess = !!params?.addBagStatus
        if (
          (quickCartSuccess && this.cpbsTimingAbt === 'noaddbag') ||
          (!quickCartSuccess && this.cpbsTimingAbt === 'addbag')
        ) {
          return
        }
        requestCPBSParams = this.targetProductCard
      }

      // 视频商品不触发点后推搜索词
      if (requestCPBSParams?.product?.video_infos?.[0]?.url && this.infoFlowShowVideo) {
        return
      }

      // 若户点击同一入口坑位2次及以上时，不再触发点后推搜索词
      if (
        this.cpbsGoodIdList.includes(`${requestCPBSParams?.product?.goods_id}`)
      ) {
        return
      }

      // 需被点击的商卡在屏幕内完全露出 且 距离屏幕边缘有40pt的宽度阈值，才推出点后推搜索词
      if (type === clickPushBackEventType.clickProductCard && this.cpbsStyleAbt === 'B') {
        const itemEl = this.getProductListItemInstance(requestCPBSParams?.index)
        if (!itemEl?.$el) {
          return
        }
        const itemSize = itemEl.$el?.getBoundingClientRect()
        const bottomHeight = window.innerHeight - itemSize.bottom
        const topHeight = itemSize.top
        // todo 确认顶部高度
        // 47为底部导航栏， 100为页面头部高度
        if (bottomHeight < 40 + 47 || topHeight < 40 + 100) {
          return
        }
      }

      let requestParams = {
        goods_id: requestCPBSParams?.product?.goods_id,
        goods_cate_id: requestCPBSParams?.product?.cat_id,
        use_scene: 1, // 列表、搜索、信息流点后推
        click_card_value: this.cpbsStyleAbt,
      }

      // 初始化点推promise
      if (this.cpbsStyleAbt === 'B') {
        this.delayRefresh?.initSlotRequestPromise()
      }

      requestCPBSPromise = new Promise(resolve => {
        requestRecommendSearchWords(requestParams).then(res => {
          resolve({
            product: requestCPBSParams?.product,
            index: requestCPBSParams?.index,
            searchWordsInfo: res,
          })
          this.delayRefresh?.resolveSlotRequest()
        })
      }).catch(() => {
        this.delayRefresh?.resolveSlotRequest()
      })
    },
    async handleShowCPBS({ type, params }) {
      // 加车
      // if (type === clickPushBackEventType.openQuickCartResult) {
      //   console.log(params)
      // }
      if (type === clickPushBackEventType.reEnter) {
        // 非信息流/商详返回页面
        if (!(params?.isBackFromPicked || this.isJumpDetail && params?.isBackFromDetail)) {
          return
        }
        // 商详返回，加车条件判断
        if (['addbag', 'noaddbag'].includes(this.cpbsTimingAbt)) {
          const detailAddCartInfo = getFeedbackRecInSession()
          if (detailAddCartInfo?.goodsId) {
            const quickCartSuccess = !!detailAddCartInfo?.addBagStatus
            if (
              (quickCartSuccess && this.cpbsTimingAbt === 'noaddbag') ||
              (!quickCartSuccess && this.cpbsTimingAbt === 'addbag')
            ) {
              return
            }
          }
        }
      }
      if (requestCPBSPromise) {
        const res = await requestCPBSPromise
        // 若户点击同一入口坑位2次及以上时，不再触发点后推搜索词
        if (this.cpbsGoodIdList.includes(`${res?.product?.goods_id}`)) {
          return
        }

        const index = res?.index || 0
        const currentProductInfo = this.products?.[index]
        let customTouchExposeParams = null
        if (res?.searchWordsInfo?.wordList) {
          // console.log(res)
          let propsParams = {
            language: this.context?.language?.itemLanguage,
            listData: res?.searchWordsInfo?.wordList,
            afterCardInfo: res?.searchWordsInfo?.afterCardInfo,
            sourceGoodsId: `${res?.product?.goods_id}`,
            traceId: res?.searchWordsInfo?.traceId,
            resultContent: res?.searchWordsInfo?.resultContent,
            abTestStr: this.cpbsAbtTest,
          }
          // 商卡弹窗
          if (['A', 'C'].includes(this.cpbsStyleAbt)) {
            // emitShowProductCardPopup({
            //   ...res
            // })
            const itemEl = this.getProductListItemInstance(index)
            if (!itemEl?.$el) {
              console.error('找不到商卡元素')
            }

            if (this.cpbsStyleAbt === 'A') {
              Object.assign(propsParams, {
                catName: this.context?.language?.itemLanguage?.SHEIN_KEY_PWA_34249,
                needCcc: true,
              })
            } else if (this.cpbsStyleAbt === 'C') {
              Object.assign(propsParams, {
                titleText: this.context?.language?.itemLanguage?.SHEIN_KEY_PWA_32040,
              })
              // 屏蔽埋点
              if (currentProductInfo) {
                currentProductInfo.customTouchExpose = params => {
                  // 获取埋点上报参数，在点击关闭按钮时重新上报
                  customTouchExposeParams = params
                }
              }
            }
            createCPBSPopup({
              parentEl: itemEl.$el,
              params: propsParams,
              popupTypeBranch: this.cpbsStyleAbt,
              router: this.$router,
              onClosePopup: () => {
                if (currentProductInfo && customTouchExposeParams) {
                  currentProductInfo.customTouchExpose = null
                  daEventCenter.triggerNotice({
                    daId: customTouchExposeParams.id,
                    target: [itemEl.$el],
                    bindData: [customTouchExposeParams.data],
                  })
                }
              },
            })
            // 商品下方插入元素
          } else if (this.cpbsStyleAbt === 'B') {
            // const searchWordsContent = res.searchWordsInfo

            // 记录当前点击
            Object.assign(propsParams, {
              type: 'recommend-search-card',
              titleText: this.context?.language?.itemLanguage?.SHEIN_KEY_PWA_32040,
              localIndex: index,
              abTestStr: this.cpbsAbtTest,
            })
            if (this.delayRefresh?.listenRefreshDeletePromise) {
              await this.delayRefresh?.listenRefreshDeletePromise()
            }
            this.delayRefresh?.initSlotAddPromise()
            this.setGoods([propsParams], 1, false)
            this.delayRefresh?.resolveSlotAdd({ error: false })
          }
          // 已推出点后推搜索词的坑位的上一坑位和下一坑位，不再触发点后推搜索词
          this.cpbsGoodIdList.push(`${this.products[index - 1]?.goods_id}`)
          this.cpbsGoodIdList.push(`${res?.product?.goods_id}`)
          this.cpbsGoodIdList.push(`${this.products[index + 1]?.goods_id}`)
        }
      }
    },
    // 根据页面类型与abt判断是否需要点后推搜索词
    handleCPBSAbt() {
      let poskey = 'FlowClickReco'
      let enterValue = 'FlowClickRecoEntry'
      let styleValue = 'FlowClickRecoCard'
      const cpbsTimingAbt = this.abtInfoV3?.[poskey]?.p?.[enterValue]
      const cpbsStyleAbt = this.abtInfoV3?.[poskey]?.p?.[styleValue]
      let abtInfo = {}
      abtInfo[poskey] = this.abtInfoV3?.[poskey]
      // 注意middlewares/product_list/dependencies.js中的声明，为了解决abt中的child被过滤问题（即使在客户端请求文件中配置了对应的abt也不行;）
      // eslint-disable-next-line @shein-aidc/abt/abt
      this.cpbsAbtTest = abtservice.getUserAbtResultForAnalysis({
        posKeys: poskey,
        abtInfo,
      }).sa
      this.cpbsTimingAbt = cpbsTimingAbt
      this.cpbsStyleAbt = cpbsStyleAbt
      this.infoFlowShowVideo = !!this.abtInfoV3?.InfoFlowShowVideo?.p?.InfoFlowShowVideo
      return (
        ['click', 'addbag', 'noaddbag'].includes(cpbsTimingAbt) &&
        !!cpbsStyleAbt
      )
    },
    handleInitCPBSWatcher() {
      this.offCPBSWatcher()
      if (!this.handleCPBSAbt()) {
        return
      }
      const requestWatchList = [
        clickPushBackEventType.clickProductCard,
        this.cpbsTimingAbt === 'click'
          ? ''
          : clickPushBackEventType.openQuickCartResult,
        this.cpbsTimingAbt === 'click'
          ? ''
          : clickPushBackEventType.reEnterPick,
      ].filter(Boolean)
      const showWatchList = [clickPushBackEventType.reEnter]
      offTouchCPBSRequestInInformationFlow =
        onTouchCPBSRequestInInformationFlow(
          this.handleRequestCPBS,
          requestWatchList,
        )
      offTouchCPBSShowInInformationFlow = onTouchCPBSShowInInformationFlow(
        this.handleShowCPBS,
        showWatchList,
      )
    },
    offCPBSWatcher() {
      if (typeof offTouchCPBSRequestInInformationFlow === 'function') {
        offTouchCPBSRequestInInformationFlow()
      }
      if (typeof offTouchCPBSShowInInformationFlow === 'function') {
        offTouchCPBSShowInInformationFlow()
      }
    },
  },
})
