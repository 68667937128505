<template>
  <!-- v-if 频道切换后，轮播重新渲染，保证自动轮播 -->
  <Transition
    appear
    :name="hideTransitionStyle"
    :mode="(
      hideTransitionStyle === 'none'
        ? (void 0)
        : 'out-in'
    )"
  >
    <div
      v-if="show && !isClosed && items.length"
      id="j-floating__ctn"
      ref="floatingIconBox"
      class="c-floating__ctn mshe-z-returnTop"
      :class="{ 'float-icon__hide': scrollHide, 'float-icon__hide-for-sbc': hideFloatIconForSbc }"
      :style="{'top': `${iconTop}rem` }"
    >
      <div
        class="c-floating__main j-floating__main handle"
        :style="{ 
          top: `${iconPosition.move}px`,
        }"
        tabindex="0"
        role="button"
        @touchstart="handleTouchStart"
        @touchmove.prevent.stop="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <div class="c-floating__main__inner">
          <swiper-container
            ref="floatingSwiper"
            class="c-floating__main__inner__container"
            init="false"
            destroy-on-disconnected="false"
          >
            <swiper-slide
              v-for="(item, index) in items"
              :key="index"
            >
              <BaseImg
                v-expose="getAnalysisData('2-22-1', { item, index, useBffApi: true })"
                v-tap="getAnalysisData('2-22-2', { item, index, useBffApi: true })"
                class="base-img c-floating__main__inner__container__slide__img"
                :placeholder="{
                  width: item.image.width,
                  height: item.image.height,
                }"
                :ratio="item.image.ratio"
                :img-src="item?.image?.src"
                :first-screen="false"
                :imgDataExp="{
                  useWebp: item.useWebp,
                }"
                fit="contain"
                :special-radius="GB_cssRight ? '0 4% 4% 0' : '4% 0 0 4%'"
              />
            </swiper-slide>
          </swiper-container>
          <div
            class="c-floating__main__inner__close-icon"
            :style="{'visibility': show ? '' : 'hidden' }"
            @click="close"
          >
            <div class="c-floating__main__inner__close-icon__relative">
              <div class="icon-background"></div>
              <Icon
                name="sui_icon_close_10px"
                color="#fff"
                size="11"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
/* globals gbFixedContainerStyle */
import common from './mixins/common'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import schttp from 'public/src/services/schttp'
import { transformImg, debounce, getQueryString, throttle } from '@shein/common-function'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
import { mapState, mapGetters } from 'vuex'
// components
import { Icon } from '@shein-aidc/icon-vue3'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()
const { hasPolicyBanner = false, SiteUID, GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
let timer = null
export default defineComponent({
  name: 'FloatingIcon',
  directives: {
    expose,
    tap,
  },
  components: {
    Icon, 
    BaseImg
  },
  mixins: [common, mixins],
  props: {
    channelName: {
      type: String,
      default: ''
    },
    // 悬浮组件abt
    floatingIconAbt: {
      type: Object,
      default: () => ({})
    },
    // TODO: 临时方案, 为了兼容mixins中的index变量
    index: {
      type: Number,
      default: 0
    },
    scrollDistAbt: {
      type: String,
      default: ''
    }
  },
  data() {
    this.scrollDistForShow = false 
    //this.isNeedHideForSbc = false
    return {
      alwaysShowIcon: false,
      isNeedHideForSbc: true,
      hideFloatIconForSbc: true,
      pageType: 'floatingIcon',
      isClosed: false,
      hideForSbc: false,
      hideTransitionStyle: 'slide-fade',
      gbFixedContainerStyle,
      hasPolicyBanner,
      iconTop: 0,
      scrollHide: false,
      propData: {},
      cateLinks: {},
      cateInfo: {},
      iconCccPublic: {},
      iconPosition: {
        start: 0, // 鼠标按下时候的位置
        moveStartY: 0, // 上一次icon偏移的距离
        move: 0, // icon 需要偏移多少
        maxMove: 0 // 最大位移高度
      },
      abtInfo: {},
      floatingSwiper: null,
      items: [],
      previewChannelId: getQueryString({ key: 'contentId' }) || false, // ccc-admin预览
      debugger: getQueryString({ key: 'debugger' }) || false,
      scrollHideTime: 0, // 显示后再隐藏的间隔时间
      GB_cssRight,
      isSwiperInitDone: false,
      contentMap: {}, // 各tab下的数据集合避免相同数据重复请求
      newTopBannerDone: false, // 新顶部banner是否加载完成，加载完成后，不再计算
    }
  },
  computed: {
    ...mapState(['commonHeight', 'hasNewTopBanner']),
    ...mapState('config_index', ['context']),
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    // 是否显示
    show() {
      return (!this.hideForSbc && this.items.length)
    },
    // 当前频道，首页
    curChannelId() {
      return this.previewChannelId || this.channelId
    },
    // 页面跳转参数
    sceneData() {
      const { id = '' } = this.propData || {}
      return {
        pageFrom: this.pageType,
        pageType: this.pageType,
        sceneName: this.channelName,
        tabName: this.channelName,
        id,
      }
    },
  },
  watch: {
    channelId(){
      this.hideFloatIconForSbc = false
      nextTick(()=>{
        this.judgePosition()
      })
    },
    scrollHide(newVal) {
      // 悬浮球侧边隐藏时，停止轮播
      newVal ? this.pauseSwiperAutoplay() : this.playSwiperAutoplay()
    },
    async curChannelId() {
      // 切换频道后再获取数据
      await this.init(true)
    },
    // hideForSbc(isNowHideForSbc) {
    //   if (isNowHideForSbc) {
    //     // 整体隐藏时，保证滚动时只处理滑动距离检测逻辑
    //     window.removeEventListener('scroll', this.scrollHideFun)
    //     window.removeEventListener('scroll', this.scrollShowFun)
    //   } else {
    //     this.scrollHide = false // 刚要显示出不应隐藏一半
    //     nextTick(() => {
    //       // 当显示时，再加入 滚动隐藏一半的逻辑
    //       window.addEventListener('scroll', this.scrollHideFun)
    //       window.addEventListener('scroll', this.scrollShowFun) // 滚动后显示icon的防抖
    //     })
    //   }
    // },
    items: {
      handler (newVal, oldVal) {
        if(typeof window == 'undefined') return
        // 数据变化后且新数据有icon重新初始化swiper
        if (newVal?.length && newVal?.length !== oldVal?.length) {
          nextTick(() => {  
            this.initSwiper()
          })
        }
      },
      deep: true,
      immediate: true,
    },
    show(newVal, oldVal) {
      if (
        newVal // 当前要展示
        && !oldVal // 之前没有展示
      ) {
        nextTick(() => {
          this.initSwiper()
        })
      }
    },
    hasNewTopBanner(newVal, oldVal) {
      if (
        newVal // 当前要展示
        && !oldVal // 之前没有展示
        && !this.newTopBannerDone // 新顶部banner未加载完成
      ) {
        this.calcIconTop()
      }
    },
  },
  async mounted() {
    this.addBranchLinstner()
    // this.$router.beforeEach((to, from, next) => {
    //   // 检查 activeChannelId 是否变化
    //   if (to.query.activeChannelId !== from.query.activeChannelId) {
    //     // 切频道时，重置隐藏状态，不要 icon 退场动画
    //     this.isSwitchingChannel = true
    //     this.hideTransitionStyle = 'none'
    //     nextTick(() => {
    //       this.hideForSbc = this.isNeedHideForSbc
    //       next()
    //     })
    //   } else {
    //     next()
    //   }
    // })
    this.curChannelId && await this.init()
    // setTimeout(() =>{
    //   this.judgePosition('mounted')
    // }, 500)
    this.judgePosition()
    window.addEventListener('scroll', this.scrollHideFun)
    window.addEventListener('scroll', this.scrollShowFun)
  },
  activated() {
    if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) return
    // 进入页面后返回，组件激活即开始监听和轮播
    this.initScroll()
    this.initSwiper()
  },
  deactivated() {
    if (requestIdleCallback) {
      requestIdleCallback(() => {
        this.handleDeactivated()
      })
    } else {
      setTimeout(() => {
        this.handleDeactivated()
      }, 0)
    }
  },
  methods: {
    addBranchLinstner() {
      window.vBus?.on('onBranchShow', this.handleBranchShow)
      window.vBus?.on('onBranchHide', this.handleBranchHide)
    },
    removeBranchLinstner() {
      window.vBus?.off('onBranchShow', this.handleBranchShow)
      window.vBus?.off('onBranchHide', this.handleBranchHide)
    },
    // app下载打开
    handleBranchShow(){
      this.calcIconTop()
    },
    // app下载关闭
    handleBranchHide(){
      this.calcIconTop()
    },
    handleDeactivated() {
      // 离开组件销毁监听，降低性能消耗
      // this.dragdealer = null
      // 移除事件监听器
      window.removeEventListener('scroll', this.scollForFirstRender)
      // 组件不可见后，重置显示，保证返回页面后正常轮播
      window.removeEventListener('scroll', this.scrollHideFun)
      window.removeEventListener('scroll', this.scrollShowFun)
      this.scrollHide = false
      this.hideForSbc = this.isNeedHideForSbc
      this.removeBranchLinstner()
    },
    transformImg,
    // 处理swiper为loop时 事件绑定的问题
    initSwiper() {
      nextTick(() => {
        if (typeof window === 'undefined') return
        const swiperEl = this.$refs.floatingSwiper
        if (!swiperEl) return
        const swiperParams = {
          modules: [Autoplay],
          direction: 'vertical',
          loop: true,
          spaceBetween: 0,
          speed: 1000,
          slidesPerView: 1,
          slidesPerGroup: 1,
          allowTouchMove: false,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false,
          },
        }
        Object.assign(swiperEl, swiperParams)
        swiperEl.initialize()
        if (this.items?.length && this.items.length > 1) {
          // 切换后重新开始轮播
          this.$nextTick(() => {
            this.playSwiperAutoplay()
          })
        }
      })
    },
    // 处理swiper为loop时 事件绑定的问题
    handleSlideClick() {
      //悬浮icon右侧收起后，点击不触发
      if (this.scrollHide) return
      let realIndex = this.$refs.floatingSwiper?.swiper?.realIndex || 0
      let item = this.items[realIndex]
      let url = this.cccLink.getFullLink({
        item: item,
        cateLinks: this.cateLinks,
        compIndex: 0,
        index: realIndex,
        isBff: true
      })
      this.jumpLink({ url, hrefType: item.hrefType })
    },
    playSwiperAutoplay() {
      this.$refs.floatingSwiper?.swiper?.autoplay?.start?.()
    },
    pauseSwiperAutoplay() {
      this.$refs.floatingSwiper?.swiper?.autoplay?.stop?.()
    },
    getTouches(e) {
      return (
        (e.targetTouches && (e.targetTouches[0] || e.changedTouches[0])) || {}
      )
    },
    handleTouchStart(e) {
      const { pageY } = this.getTouches(e)
      this.iconPosition.start = pageY
      this.iconPosition.moveStartY = this.iconPosition.move
      // 防止点击跟拖拽冲突
      timer = setTimeout(() => {
        this.handleSlideClick()
      }, 200)
    },
    handleTouchMove(e) {
      // 拖拽场景不触发点击
      clearTimeout(timer)
      timer = null
      const { pageY } = this.getTouches(e)
      // 偏移量
      const maxMove = this.iconPosition.maxMove
      const distance = this.iconPosition.moveStartY + pageY - this.iconPosition.start
      const move = distance < 0 ? 0 : distance > maxMove ? maxMove : distance
      this.iconPosition.move = Number(move) 
      this.pauseSwiperAutoplay()
    },
    handleTouchEnd() {
      // 记录上一次上下滑动位置
      window.sessionStorage?.setItem(SiteUID + '-floating-icon-pos', this.iconPosition.move)
      this.playSwiperAutoplay()
      this.judgePosition()
    },
    judgePosition(){
      const { floatingicon_sbc_switch: iconSbcSwitch = '0' } = this.context?.content?.homePageExtra?.webDynamicAbt ?? {}
      const curTab = this.topTabList?.find((tab) => tab.channelId === this.channelId)
      const isAll = curTab?.channelName === 'all' // 非all频道不做处理
      const sbcEl  = document.querySelector(`.category-recommend-placeholder__container-${this.channelId}`)
      if((!isAll || !sbcEl || iconSbcSwitch == '0' || !iconSbcSwitch || this.alwaysShowIcon)){
        this.hideFloatIconForSbc = false
        return 
      }
      const { top, bottom } = sbcEl?.getBoundingClientRect() ?? {}
      const { top: elTopDistance, bottom: elBottomDistance } = document.querySelector('.c-floating__main')?.getBoundingClientRect() ?? {}
      // 滑动页面展示时半隐藏，
      if((elTopDistance >= top && elTopDistance <= bottom) || (elBottomDistance >= top && elBottomDistance <= bottom)){
        // 隐藏
        this.hideFloatIconForSbc = true
      } else {
        // 显示
        this.hideFloatIconForSbc = false
        if(iconSbcSwitch == '2') {
          this.alwaysShowIcon = true
        }
      }
    },
    async init(isChannelChange = false) {
      if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) {
        this.isClosed = true
        return
      }
      await this.fetchData(!this.contentMap[this.curChannelId], isChannelChange)
      this.initScroll()
      if (!isChannelChange ) { // tab变化不重新计算，减少计算消耗
        this.calcIconTop()
      }
      this.handleSlslog()
    },
    // 计算拖动的上距离
    calcIconTop() {
      let top = this.hasPolicyBanner ? `${+(gbFixedContainerStyle.top.replace('rem', '')) - +(gbFixedContainerStyle.policybanner.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', ''))}` : `${+(gbFixedContainerStyle.top.replace('rem', '')) + +(gbFixedContainerStyle.branch.replace('rem', ''))}`
      top = isNaN(top) ? 0 : Number(top)
      // 顶部banner加载完成后，计算icon位置
      if (this.hasNewTopBanner) {
        top = top + 1.04 // 顶部banner高度，固定1.04rem
        this.newTopBannerDone = true
      }
      if (this.commonHeight) { // 头部+tab高度
        const topHeaderHeight = this.commonHeight?.topHeaderHeight || 0
        const topTabHeight = this.commonHeight?.topTabHeight || 0
        top = top + topHeaderHeight + topTabHeight
      }
      this.iconTop = top
      const initMoveHeight = this.calcInitMoveHeight()
      this.iconPosition.maxMove = initMoveHeight
      this.iconPosition.move = Number(window.sessionStorage?.getItem(SiteUID + '-floating-icon-pos') || initMoveHeight || 1)
    },
    // 计算滑动距离
    calcInitMoveHeight() {
      const htmlFontSize = parseFloat(getComputedStyle(document.documentElement)?.fontSize) ?? 0
      // 页面高度 - 顶部距离(banner+header+tablist) - bottom固定的4.9rem
      const top = this.iconTop
      const topHeight = parseFloat(top)
      const footerHeight = 6.4 // 4.9rem bottom
      const remainingHeight = document.documentElement.clientHeight - topHeight * htmlFontSize - footerHeight * htmlFontSize
      return remainingHeight.toFixed(0)
    },
    // 节流
    scrollHideFun: throttle({
      func: function () {
        // - 隐藏状态不需要处理此逻辑
        // - 切换频道时不需要半隐藏
        if(this.isNeedHideForSbc) this.scrollHide = true
        if (this.isSwitchingChannel) return

        const gapTime = new Date().getTime() - this.scrollHideTime
        // 计算显示后再隐藏时间，续大于动画完成时间，防止显示后马上再滚动马上隐藏造成的抖动
        if (!this.scrollHide && (!this.scrollHideTime || gapTime > 500)) {
          if(this.isNeedHideForSbc) this.scrollHide = true
        }
      },
      wait: 500,
    }),
    // 防抖
    scrollShowFun: debounce({ 
      func: function () {
        //if (this.hideForSbc) return // 隐藏状态不需要处理此逻辑

        this.scrollHideTime = new Date().getTime()
        this.scrollHide = false
      },
      wait: 500,
    }),
    // 滚动距离足够时 开启显示
    scollForFirstRender: throttle({
      func: function () {
        const isScrollOver = window.scrollY >= this.scrollDistForShow
        
        // !! 为什么要这样写这个 if ：
        // 因为 Vue2 赋值 this.hideForSbc 状态是会触发响应式相关操作的，
        // 开销相对较大，但做条件判断的成本小得多
        if (this.hideForSbc) {
          if (isScrollOver) this.hideForSbc = false // 当前隐藏、滑动够距离 -> 取消隐藏
        } else {
          if (!isScrollOver) this.hideForSbc = true // 当前显示、滑动回不够距离 -> 隐藏
        }

        // 切换频道后，一定会检查一次是否需要为 sbc 隐藏
        // 当检查结束后，关闭开关标志
        if (this.isSwitchingChannel) {
          this.isSwitchingChannel = false
          this.hideTransitionStyle = 'slide-fade'
        }
        this.judgePosition()
      },
      wait: 500,
    }),
    // 页面滚动监听
    initScroll() {
      if (!this.items.length) {
        return
      }
      Monitor.metricFloatComp({
        tags: {
          track_type: '3',
          layer_type: 'FloatingIcon',
        },
      })
      setTimeout(() => {
        this.scrollHide = false
      }, 500)

      // 监听滚动确定是否显示icon
      window.addEventListener('scroll', this.scollForFirstRender)
    },
    async bffIconRequest() {
      const params = {
        cccPageType: 'floatingIcon',
        channelId: this.curChannelId,
        tab_name: this.channelName,
      }
      try {
        const { config: { headers = {} } = {}, data: res } = await schttp({
          url: '/ccc/resource_place',
          method: 'GET',
          useBffApi: true,
          params,
          schttp: { getResponse: true }, // 需要uber-trace-id，返回所有响应数据
        })
        let content = res?.info || {}
        content._traceId = headers?.['uber-trace-id'] || ''
        return {
          content,
          code: res?.code,
        }
      } catch (error) {
        console.error('Error in bffIconRequest:', error)
        return {
          content: {},
          code: -1
        }
      }
    },
    async fetchData(checkoutRequest, isChannelChange) {
      const { isSupportWeb = '' } = this.context?.RESOURCE_SDK || {}
      if (window.sessionStorage?.getItem(SiteUID + '-floating-icon-close')) return
      if (!this.curChannelId) return
      let res = {}
      let { code, content } = res
      if (checkoutRequest) {
        res = await this.bffIconRequest()
        code = res.code
        content = res.content
      } else {
        code = 0
        content = this.contentMap[this.curChannelId]
      }
      this.contentMap[this.curChannelId] = content
      if (code == 0) {
        this.propData = content?.content?.[0] || {}
        this.cateLinks = content?.cateLinks || {}
        let items = []
        if (content?.content?.[0]?.props?.items.length) {
          // 逐帧显示 abt 控制
          content?.content?.[0]?.props?.items.forEach((element) => {
            if (element.image?.src) {
              let transformUrl = this.transformImg({ img: element.image?.src })
              element.useWebp = 0
              if (/\.(jpg|jpeg|png)$/.test(transformUrl)) {
                if (isSupportWeb === 1) {
                  transformUrl =  transformUrl.replace(/\b.(jpg|jpeg|png)\b/, '.webp')
                  element.useWebp = 1
                }
              }
              element.image.src = transformUrl
              items.push(element)
            }
          })
        }
        this.items = items
        if (isChannelChange) {// teb切换后重新曝光
          daEventExpose?.resetAll?.(this.sceneData?.pageFrom)
        }
      }
    },
    close() {
      clearTimeout(timer)
      timer = null
      this.isClosed = true
      window.sessionStorage?.setItem(SiteUID + '-floating-icon-close', 1)
      Monitor.metricFloatComp({
        tags: {
          track_type: '4',
          layer_type: 'FloatingIcon',
        },
      })
    },
    handleSlslog() {
      this.items.map(el => {
        if(!el?.image?.src) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'CCCDataError',
            reason: 'imageError',
            extraInfo: {
              frame: el?.frame,
              hrefTarget: el?.hrefTarget,
              hrefType: el?.hrefType,
              traceId: this.contentMap?.[this.curChannelId]?._traceId || ''
            }
          })
        }
      })
    },
  },
})
</script>

<style lang="less">
@vw: 375/100vw;
.c-floating {
  &__ctn {
    position: fixed;
    right: 0;
    bottom: 4.9rem;// 72+47+65
    top: 2rem;
    width: 52 / @vw;
    pointer-events: none;
    transition: transform .5s, opacity .5s;
    &.float-icon__hide {
      transform: translateX(30 / @vw);
      opacity: 0.5;
    }
  }
  &__main {
    position: absolute;
    right: 0;
    width: 1.39rem;
    height: 1.73rem;
    text-align: center;
    pointer-events: all;
    &__inner { /* stylelint-disable-line */
      position: relative;
      width: 100%;
      height: 100%;
      &__container { /* stylelint-disable-line */
        width: 4.17rem;
        height: 5.19rem;
        transform: translate(-33.33%, -33.33%) scale(0.3333); // 最后再缩小1/3，容器固定32*38自适应大小
        overflow: hidden;
        &__slide__img { /* stylelint-disable-line */
          width: 100%;
          height: 100%;
        }
      }
      &__close-icon { /* stylelint-disable-line */
        width: 0.5333rem;
        height: 0.32rem;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(0, -100%);
        pointer-events: all;
        overflow: hidden; // hidden 保证点击热区为矩形而非icon组件的正方形
        will-change: transform; // 部分安卓手机渲染丢帧
        &__relative { /* stylelint-disable-line */
          position: relative;
          width: 100%;
          height: 100%;
          .icon-background {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-image: url('//img.ltwebstatic.com/images3_ccc/2024/08/19/1a/1724068000332b363f18576ea6f51e751a83d63b18.png');
            background-size: contain;
            background-repeat: no-repeat;
          }
          .sui-icon-common__wrap {
            position: absolute;
            bottom: 0;
            line-height: 10px !important; /* stylelint-disable-line declaration-no-important */
            right: 3px /* rtl: 2px */;
          }
        }
      }
    }
  }
}
// 中东镜像
[mir=rtl] {
  .c-floating__main__inner__close-icon { /* stylelint-disable-line */
    transform: translate(0, -100%) scale(-1,1);
  }
}
</style>

<style lang="less" scoped>
@vw: 375/100vw;
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
 .float-icon__hide-for-sbc {
   opacity: 0;
   transform: translateX(60 / @vw);
 }
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(60 / @vw);
  opacity: 0;
}

// 无动画，直接闪现
.none-enter-active,
.none-leave-active {
  transition: none;
}

.none-enter-from,
.none-leave-to {
  opacity: 0;
}
</style>

